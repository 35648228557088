import { i18n } from '@/i18n';
export default function (form, context) {
    return {
        username: [
            {
                required: true,
                trigger: ['blur'],
                validator(rule, value, callback) {
                    if (!value) {
                        if (['email', 2].includes(form.login_type)) {
                            callback(new Error(i18n.t('global.email_placeholder')));
                        }
                        else if (form.login_type === 'account') {
                            callback(new Error(i18n.t('global.username_placeholder')));
                        }
                    }
                    if (['email', 2].includes(form.login_type) && !KLOOK.isEmail(value)) {
                        callback(new Error(i18n.t('global.tips_email_wrong')));
                    }
                    if (['phone', 3].includes(form.login_type)) {
                        const phone = value && value.split('-');
                        if (!phone[1]) {
                            callback(new Error(i18n.t('global.phone_placeholder')));
                        }
                        else {
                            callback();
                        }
                    }
                    if (form.login_type === 'account') {
                        if (!/^[a-zA-Z0-9_]+$/.test(value)) {
                            callback(new Error(i18n.t('global.tips_username_wrong')));
                        }
                    }
                    callback();
                },
            },
        ],
        password: [
            {
                required: true,
                trigger: ['blur'],
                validator(rule, value, callback) {
                    if (!value) {
                        callback(new Error(i18n.t('global.password_placeholder')));
                    }
                    else if (!KLOOK.isValidPwd(value)) {
                        callback(new Error(i18n.t('global.tips_password_wrong')));
                    }
                    else {
                        callback();
                    }
                },
            },
        ],
        confirm_password: [
            {
                required: true,
                trigger: ['blur'],
                validator(rule, value, callback) {
                    if (!value) {
                        callback(new Error(i18n.t('global.confirm_new_password_placeholder')));
                    }
                    else if (form.password !== value) {
                        callback(new Error(i18n.t('global.tips_confirm_password_wrong')));
                    }
                    else {
                        callback();
                    }
                },
            },
        ],
        old_password: [
            {
                required: true,
                trigger: ['blur'],
                validator(rule, value, callback) {
                    if (!value) {
                        callback(new Error(i18n.t('settings.current_password_placeholder')));
                    }
                    else if (!KLOOK.isValidPwd(value)) {
                        callback(new Error(i18n.t('global.tips_password_wrong')));
                    }
                    else {
                        callback();
                    }
                },
            },
        ],
        new_password: [
            {
                required: true,
                trigger: ['blur'],
                validator(rule, value, callback) {
                    if (!value) {
                        callback(new Error(i18n.t('global.new_password_placeholder')));
                    }
                    else if (!KLOOK.isValidPwd(value)) {
                        callback(new Error(i18n.t('global.tips_password_wrong')));
                    }
                    else {
                        callback();
                    }
                },
            },
        ],
        confirm_new_password: [
            {
                required: true,
                trigger: ['blur'],
                validator(rule, value, callback) {
                    if (!value) {
                        callback(new Error(i18n.t('global.confirm_new_password_placeholder')));
                    }
                    else if (!value || form.new_password !== value) {
                        callback(new Error(i18n.t('global.tips_confirm_password_wrong')));
                    }
                    else {
                        callback();
                    }
                },
            },
        ],
        verify_code: [
            {
                required: true,
                trigger: 'blur',
                validator(rule, value, callback) {
                    if (!value) {
                        callback(new Error(i18n.t('signup.code_placeholder')));
                    }
                    else if (value.length !== 6 || /\D/.test(value)) {
                        callback(new Error(i18n.t('26648')));
                    }
                    else {
                        callback();
                    }
                },
            },
        ],
    };
}
